.details {
  &__heading {
    margin: 0 0 20px 0;
    border-bottom: var(--line);
    padding: 0 0 20px 0;
    font-size: var(--font-size-subheading);
    font-weight: bold;
  }

  &__item {
    margin: 0 0 10px 0;
    font-size: var(--font-size-regular);
  }

  &__download {
    display: inline-block;
    margin: 10px 0 0 0;
    padding: 6px 24px;
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--color-primary);
    transition: background-color 300ms;

    &:hover {
      background-color: var(--color-brown-dark);
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: transparent;
    cursor: pointer;
  }

  &__icon {
    fill: var(--color-primary);
    transition: fill 300ms;

    &:hover {
      fill: var(--color-brown-dark);
    }
  }
}
