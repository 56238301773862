.loader {
  min-height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  &__spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px dashed;
    animation: rotate 2s infinite linear;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  &__text {
    font-weight: bold;
  }
}
