.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background-color: var(--color-backdrop);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 30;
  width: 100%;
  max-width: 640px;

  padding: 20px;

  animation: slide-down 300ms ease-out forwards;

  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translate(-50%, -70%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.content {
  background-color: white;
  padding: 30px 20px;
}
