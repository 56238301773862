.table {
  &__header {
    padding: 20px;
    color: var(--color-white);
    background-color: var(--color-primary);

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}
