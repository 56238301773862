.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-footer);
  font-size: var(--font-size-footer);
  color: var(--color-white);
  background-color: var(--color-primary);

  &__container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__block {
    margin: 0 80px 0 0;

    @media only screen and (max-width: 700px) {
      margin: 0 0 20px 0;
    }
  }

  &__header {
    font-weight: bold;
    margin: 0 0 8px 0;
  }

  &__item {
    margin: 0 0 4px 0;

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    text-decoration: none;
    transition: color 300ms;

    &:hover {
      color: #e5dfdb;
    }
  }

  &__copyright {
    text-align: center;
    font-weight: 300;
    font-size: var(--font-size-copyright);
  }
}
