.header {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: var(--padding-header);
  background-color: var(--color-white);
  box-shadow: var(--shadow-dark);

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: auto;
  }

  &__logo {
    height: 32px;

    @media only screen and (max-width: 440px) {
      height: 24px;
    }

    img {
      height: 100%;
    }
  }

  &__nav {
    @media only screen and (max-width: 700px) {
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 20;
      width: 100%;
      height: 100vh;
      background-color: var(--color-white);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: left 300ms;

      &.active {
        left: 0;
      }
    }
  }

  &__list {
    display: flex;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    position: relative;
    margin: 0 40px 0 0;
    font-size: var(--font-size-regular);
    transition: color 300ms;

    @media only screen and (max-width: 800px) {
      margin: 0 20px 0 0;
    }

    @media only screen and (max-width: 700px) {
      margin: 0 0 80px 0;
      font-size: var(--font-size-heading);
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      color: var(--color-primary);
    }
  }

  &__link {
    text-decoration: none;

    &_active {
      text-decoration: none;
      color: var(--color-primary);
    }
    &_active::before {
      content: "";
      position: absolute;
      left: 0px;
      bottom: -6px;
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--color-primary);
    }
  }

  @mixin menu-icon {
    position: absolute;
    left: 20px;
    display: none;
    width: 24px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      display: block;
    }
  }

  &__menu_open {
    @include menu-icon();

    top: 50%;
    transform: translateY(-50%);
  }

  &__menu_close {
    @include menu-icon();

    top: 18px;
  }

  &__icon {
    fill: var(--color-primary);
    transition: fill 300ms;

    &:hover {
      fill: var(--color-brown-dark);
    }
  }
}
