.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  &__caption {
    font-weight: bold;
    font-size: var(--font-size-heading);
    margin: 0 0 20px 0;
    text-align: left;

    @media only screen and (max-width: 600px) {
      margin: 0 20px 20px 0;
    }
  }
}
